.main {
    width: 100%;
}

.mainBox {
    margin: 0 auto;
    width: 8rem;
    padding: 1rem 0;
}

.stepBase {
    width: 2.5rem;
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.description {
    position: absolute;
    left: -.5rem;
    top: calc(100% + .5rem);
    width: 3.5rem;
    font-size: .8rem;
    text-align: center;
    color: #CDCDCD;
}

.descriptionAct {
    color: #9B999E;
}