.screenshotsImageBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 1.5rem 1rem 1.5rem;
}

.screenshotsImageBox>div {
    margin-top: 1rem;
}

.videoUrlTitle {
    font-size: 1rem;
    font-weight: 700;
}

.videoUriDetail {
    margin-top: .4rem;
    font-size: .9rem;
    word-break: break-all;
}

.copyLinkButton {
    font-size: 1rem;
}
.qrcodeImg{
    width: 50% !important;  
}
/* 在大于或等于 850px 像素的屏幕上，将背景色设置为蓝色 */
@media screen and (min-width: 650px) {
 
    .qrcodeImg{
        width: 30% !important;  
    }
  }