.fromBase
{
    margin-top: 1rem;
}
.titleBox
{
    text-align: left;
    padding:.3rem 1rem;
    color:#8B8C8E;
}
.main
{
    background-color: #F6F4F9;
}
.formGroup
{
    margin:0 1rem;
    background-color: #FFF;
    border-radius: 1rem;
}
/* 在大于或等于 850px 像素的屏幕上，将背景色设置为蓝色 */
@media screen and (min-width: 650px) {
 
    .adm-image-img{
        width: 50% !important;
    }
    .adm-image{
        width: 40% !important;  
    }
  }