.main {
    margin-top: .5rem;
    border-radius: .8rem;
    /* box-shadow: #CDCDCD 0rem .1rem .1rem .04rem; */
    border: 1px solid #CDCDCD;
    min-height: 6rem;
    padding: .8rem;
    background-color: #FFF;
}

.baseBox {
    display: inline-block;
    vertical-align: top;
}

.imgBox {
    width: 30%;

}

.imgBox>img {
    width: 100%;
    border-radius: 10px;
}

.detailBox {
    width: 70%;
}

.detailBox>div {
    padding-left: 1rem;
    text-align: left;
}

.detailBox>.status>a {
    background-color: #B1DED6;
    color: #14927D;
    padding: .1rem .4rem;
    display: inline-block;
    border-radius: .8rem .4rem;
    font-weight: 500;
}

.detailBox>.title {
    margin-top: .5rem;
    font-size: 1rem;
    font-weight: 700;
}

.detailBox>.datetime {
    margin-top: .5rem;
    color: #CDCDCD;
}


/* 在大于或等于 850px 像素的屏幕上，将背景色设置为蓝色 */
@media screen and (min-width: 650px) {
    .imgBox {
        width: 120px;
    
    }
    .imgBox>img {
        width: 100px;
         
    }
  }