.main {
  position: fixed;
  bottom: 0;
  /* left: 0; */
  width: calc(100% - 1rem);
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.5rem;
  /* border-top:1px solid #000; */
  background-color: #fff;
  z-index: 99;
  padding: 0 0.5rem 0.5rem 0.5rem;
  justify-content: space-between;
  /* max-width: 996px;
    margin: 0 auto; */
}
.baseItem {
  /* font-size: 0.5rem; */
  height: 3.5rem;
  font-size: 0.8rem;
}

.barItemNew {
  width: 36%;
}

.barItemNew > img {
  width: 34%;
}

.barItem {
  width: 18%;
}

.barItem > img {
  width: 50%;
}

.barItem > .title {
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
}

.createItem {
  width: 28%;
  position: relative;
}

.createItem > img {
  position: absolute;
  left: 15%;
  top: -1.05rem;
  width: 70%;
}

.barItemAct > .title {
  color: orange !important;
  font-size: 1rem;
}

.main_pc {
  position: fixed;
  bottom: 0;
  /* left: 0; */
  width: calc(100% - 10px);
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.5rem;
  /* border-top:1px solid #000; */
  background-color: #fff;
  z-index: 99;
  padding: 0 0.5rem 0.5rem 0.5rem;
  justify-content: space-between;

  max-width: 856px;
  margin: 0 auto;
}
.baseItem_pc {
  font-size: 14px;
  height: 3.5rem;
}

.barItemNew_pc {
  width: 36%;
}

.barItemNew_pc > img {
  width: 11%;
}

.barItem_pc {
  width: 18%;
}

.barItem_pc > img {
  width: 50%;
}

.barItem_pc > .title_pc {
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
}

.createItem_pc {
  width: 28%;
  position: relative;
}

.createItem_pc > img {
  position: absolute;
  left: 15%;
  top: -30px;
  width: 30%;
}

.barItemAct_pc > .title_pc {
  color: orange !important;
  font-size: 1rem;
}
@media screen and (max-width: 849px) {
    .barItemNew_pc > img {
      width: 15%;
    }
    .baseItem_pc {
      height: 3.5rem;
      font-size: 1rem;
    }
  
    .barItemNew_pc {
      width: 33%;
    }
    .createItem_pc {
      width: 33%;
      position: relative;
    }
  
    .createItem_pc > img {
      position: absolute;
      left: 35%;
      top: -30px;
      width: 30%;
    }
  }
  
@media screen and (max-width: 650px) {
  .barItemNew_pc > img {
    width: 27%;
  }
  .baseItem_pc {
    font-size: 14px;
    height: 3.5rem;
  }

  .barItemNew_pc {
    width: 33%;
  }
  .createItem_pc {
    width: 33%;
    position: relative;
  }

  .createItem_pc > img {
    position: absolute;
    left: 24%;
    top: -20px;
    width: 52%;
  }
}

/* 在大于或等于 850px 像素的屏幕上，将背景色设置为蓝色 */
@media screen and (min-width: 850px) {
  .main_pc {
    position: fixed;
    bottom: 0;
    /* left: 0; */
    width: 834px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 0.5rem;
    /* border-top:1px solid #000; */
    background-color: #fff;
    z-index: 99;
    padding: 0 0.5rem 0.5rem 0.5rem;
    justify-content: space-between;
    /* max-width: 996px;
        margin: 0 auto; */
  }
  .baseItem_pc {
    font-size: 1rem;
    height: 3.5rem;
  }

  .barItemNew_pc {
    width: 33%;
  }
  .createItem_pc {
    width: 33%;
    position: relative;
  }
  .barItemNew_pc > img {
    width: 15%;
  }
  .createItem_pc > img {
    position: absolute;
    left: 32%;
    top: -50px;
    width: 36%;
  }
}
