/* .tabInLine>div {
    margin-top: .8rem;
    display: inline-block;
    width: 30%;
    vertical-align: middle;
}

.tabInLine>div:nth-of-type(1) {
    width: 70%;
} */

/* .tabBox {
    text-align: left;
}

.searchBox {
    text-align: right;
} */
.tabInLine {
    margin-top: .8rem;
    display: flex;
    justify-content: space-between;

}

.tabBox>div:nth-of-type(1) {
    margin-left: 1rem;
}

.tabBox>div {
    display: inline-block;
    padding: 0 .2rem;
}

.tabItem {
    font-size: 1rem;
    color: #767B83;
    font-weight: 600;
}

.actTabItem {
    font-size: 1.4rem;
    margin: 0 auto;
    color: #000
}

.actLine {
    /* display: block; */
    width: 1.2rem;
    height: 0.25rem;
    background-color: #FF6D3D;
    margin-left: calc(50% - .6rem);
    border-radius: .1rem;
    margin-top: .1rem;
}

.searchButton {
    width: 1.2rem;
    height: 1.2rem;
    padding: .3rem;
    border: 1px solid #606670;
    display: inline-block;
    border-radius: 50%;
    margin-right: 1rem;
    vertical-align: middle;
}

.searchButton>img {
    width: 100%
}

.meetingTypeButton {
    width: 6rem;
    /* height: 1.2rem; */
    /* padding: .3rem; */
    display: inline-block;
    margin-right: .25rem;
    vertical-align: middle;
}

.meetingTypeButton .typeIcon {
    display: inline-block;
    vertical-align: middle;
    color: #767B83;
    font-weight: 700;
    margin-right: .25rem;
    font-size: 1rem;
}


/* 入场动画过程 */
.dg-enter {
    opacity: 0;
    transform: translateX(100%);
    height: 100vh;
}

.dg-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: 300ms;
    height: 100vh;
}

.dg-enter-done {
    opacity: 1;
    transform: translateX(0%);
    height: 100vh;
}

/* 出场动画过程 */
.dg-exit {
    opacity: 1;
    transform: translateX(0%);
    height: 100vh;
}

.dg-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: 300ms;
    height: 100vh;
}

.dg-exit-done {
    opacity: 0;
    transform: translateX(-100%);
    transition: 300ms;
    height: 100vh;
}
/* 在大于或等于 850px 像素的屏幕上，将背景色设置为蓝色 */
@media screen and (min-width: 980px) {
    .searchButton {
        width: 0.8rem;
        height:0.8rem;
        padding: .15rem;
        border: 1px solid #606670;
        display: inline-block;
        border-radius: 50%;
        margin-right: 1rem;
        vertical-align: middle;
    }
    
    .searchButton>img {
        width: 100%
    }
  }

