.main
{
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:0 1rem;
}
.main > div
{
    padding:1rem;
    width:100%;
}