.main{
    /* width:100%; */
    /* border:1px solid #000; */
    padding:0 1rem;
}

/* 在大于或等于 850px 像素的屏幕上，将背景色设置为蓝色 */
@media screen and (min-width: 650px) {
    .main{
      width: 75% !important;
      margin: 0 auto;
    }
  }