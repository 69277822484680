.App {
  text-align: center;
}

.mainBody {
  background-color: #f5f7fa;
  height: calc(100vh - 6rem);
  padding-bottom: 6rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

--active-dot-color {
  color: black;
}

.maxWidth {
  margin: 0 auto;
  max-width: 850px;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* 入场动画过程 */
.dg-enter {
  opacity: 0;
  transform: translateX(100%);
  height: 100vh;
}

.dg-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: 300ms;
  height: 100vh;
}

.dg-enter-done {
  opacity: 1;
  transform: translateX(0%);
  height: 100vh;
}

/* 出场动画过程 */
.dg-exit {
  opacity: 1;
  transform: translateX(0%);
  height: 100vh;
}

.dg-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: 300ms;
  height: 100vh;
}

.dg-exit-done {
  opacity: 0;
  transform: translateX(-100%);
  transition: 300ms;
  height: 100vh;
}
::-webkit-scrollbar {
  display: none;
}

/* :root:root {
  --adm-button-border-radius: 2px;
} */

/* 在大于或等于 850px 像素的屏幕上，将背景色设置为蓝色 */
@media screen and (min-width: 850px) {
  /* body {
    background-color: blue;
  } */
  :root:root {
    --am-container-width: "850px";
  }
  .adm-popup,
  .adm-picker-popup {
    width: 850px !important; 
    margin: 0 auto;
    align-items: center !important; 
    margin-left: calc((100vw - 850px) / 2) ; 
  }
  .adm-picker-popup,
  .adm-popup-body,
  .adm-picker-popup,
  .adm-popup-body {
    width: 850px !important; 
    margin: 0 auto;
    align-items: center !important; 
    margin-left: calc((100vw - 850px ) / 2) ; 
  }
}
