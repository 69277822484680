:root{
    --adm-color-primary:#FF6D3D;
    /* --adm-color-light:#FF6D3D;
    --adm-color-box:#FF6D3D; */
}
.main
{
    padding:1rem 1.5rem 0 1.5rem;
}
.mianbox
{
    border-bottom: 1px solid #dde0e4;
    padding-bottom: 1rem;
}
.mainboxlast
{
    border-bottom: 0;
}
.inputLabel
{
    display: inline-block;
    font-size: 1rem;
    width: 50%;
    text-align: left;
    color:#8B8C8E;
    font-weight: 500;
    vertical-align: middle;
}
.inputBox
{
    display: inline-block;
    width: 50%;
    text-align: right;
    vertical-align: middle;
}

.dropDownIcon
{
    padding-left: .3rem;
    color:#8B8C8E
}

.input
{
    flex: auto;
}
.adm_picker_pc{
  width: 100vh;
}