.collapseBox{
    margin:1.5rem 1rem 1rem 1rem;
    background-color: #FFF;
    padding: 0.25rem;
    border-radius: .5rem;
}

.main
{
    padding:1rem 1.5rem 0 1.5rem;
}
.mianbox
{
    padding-bottom: 1rem;
}
.inputLabel
{
    display: inline-block;
    font-size: 1rem;
    width: 50%;
    text-align: left;
    font-weight: 500;
    vertical-align: middle;
}
.inputBox
{
    display: inline-block;
    width: 50%;
    text-align: right;
    vertical-align: middle;
    color:#8B8C8E;
}

.dropDownIcon
{
    padding-left: .3rem;
    color:#8B8C8E
}

.input
{
    flex: auto;
}

.hasheadImgUrl{
    border-radius:8px; margin-left:10px; padding:3px 5px; color:#fff; background-color:#979994;
}
.noheadImgUrl{
    border-radius:8px; margin-left:10px; padding:3px 5px; color:#fff; background-color:#27354F;
  
}

.hasBind{
    border-radius:8px; margin-left:10px; padding:3px 5px; color:#fff; background-color:#979994;
}
.noBind{
    border-radius:8px; margin-left:10px; padding:3px 5px; color:#fff; background-color:#FF6E3E;
  
}