.imgStyle{
  width: 95%;
  margin-top: 1rem !important
}
.divbox{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* 在大于或等于 850px 像素的屏幕上，将背景色设置为蓝色 */
@media screen and (min-width: 650px) {
  .imgStyle{
    /* height: 150px; */
    width: 45%;
    margin-top: 0.5rem !important
    
  }
}