.infoBox {
    width: 100%;
    text-align: right;
    font-weight: 700;
}

.infoBox>span:nth-child(1) {
    font-size: 1.5rem;
    color: var(--adm-color-primary);
}

.infoBox>span:nth-child(2) {
    font-size: .8rem;
}

.dataChooseBox {
    padding: .5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.dateChoose {
    width: calc(50% - 1rem);
    /* height: 5rem; */
    border-radius: 1rem;
}

.left {
    background-color: #FEEFC9;
}

.right {
    background-color: #FEE3CA;
}

.dateChoose>div {
    font-weight: 700;
}

.dateChoose>div:nth-child(1) {
    margin-top: 1.5rem;
    font-size: 1rem;
    color: #3F3341
}

.dateChoose>div:nth-child(2) {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    color: #F5812C
}